import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import HeartFilled from "./images/icon-heart-filled.svg";
import HeartEmpty from "./images/icon-heart-empty.svg";

import WishistHeartFilled from "./images/wishist-icon-active.svg";
import WishistHeartEmpty from "./images/wishist-icon.svg";

import GalleryHeartFilled from "./images/gallery-icon-active.svg";
import GalleryHeartEmpty from "./images/gallery-icon-empty.svg";

import carCardV2Filled from "./images/car-card-v2-wishlist-icon-filled.svg";
import carCardV2Empty from "./images/car-card-v2-wishlist-icon.svg";

import WishlistFilled from "./images/wishlist-filled.svg";
import WishlistEmpty from "./images/wishlist-empty.svg";

const ICON_SOURCE = {
    "default": {active: HeartFilled, inActive: HeartEmpty},
    "header": {active: WishistHeartFilled, inActive: WishistHeartEmpty},
    "gallery": {active: GalleryHeartFilled, inActive: GalleryHeartEmpty},
    "carCardV2": {active: carCardV2Filled, inActive: carCardV2Empty},
    "desktopListing": {active: WishlistFilled, inActive: WishlistEmpty},
    "desktopLanding": {active: WishlistFilled, inActive: WishlistEmpty},
    "smallerCarCard": {active: carCardV2Filled, inActive: WishlistEmpty}
};

const IconHeart = ({ active, onClick = () => {}, source = "default" }) => {
    return (
        <div styleName={"styles.outer"} className={"heartWrapOuter"}>
            <button onClick={onClick}>
                <img src={
                    active ? ICON_SOURCE[source]?.active : ICON_SOURCE[source]?.inActive
                }
                width="35"
                height="29"
                alt={`${active ? "Remove from" : "Add to"} wishlist`}
                />
            </button>
        </div>
    );
};
IconHeart.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    source: PropTypes.string
};
export default IconHeart;
