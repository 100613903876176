import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RecentlyCarSectionSlider from "./component";

const mapStateToProps = () => ({ });

const mapDispatchToProps = (dispatch) => bindActionCreators({ }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecentlyCarSectionSlider));
