import { AE_HOMEPAGE_PAGINATION_SIZE, NUMBER } from "../../../constants/app-constants";
import {FILTER_ENTITIES} from "../../../utils/filters-v2/constants";

export const defaultParams = {
    size: AE_HOMEPAGE_PAGINATION_SIZE,
    page: NUMBER.ZERO,
    variant: "filterV5"
};

export const defaultFilterPayload = {
    selectedFilters: [],
    filters: {}
};

export const minWidthFilters = {
    [FILTER_ENTITIES.MAKE]: "36px",
    [FILTER_ENTITIES.PRICE]: "44px",
    [FILTER_ENTITIES.YEAR]: "27px",
    [FILTER_ENTITIES.EMI]: "20px",
    [FILTER_ENTITIES.BODY_TYPE]: "62px",
    [FILTER_ENTITIES.ODOMETER_READING]: "48px"
};
export const CLEAR_FILTER_TYPE = {
    ON_CLOSE: "ON_CLOSE",
    ON_CLEAR: "ON_CLEAR"
};

export const SAVE_FILTERS_CASES = {
    NSF_NA_NL: "NSF_NA_NL",
    YSF_NA_NL: "YSF_NA_NL",
    YA_NL: "YA_NL",
    NSF_YA_NL: "NSF_NA_NL",
    YSF_NA_YL: "YSF_NA_YL"
};
