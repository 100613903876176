import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SmallerCarCard from "./component";

const mapStateToProps = ({
    user: {
        secureToken,
        isLoggedIn
    }
}) => ({
    secureToken,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SmallerCarCard);
